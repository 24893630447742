* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
}

form {
  width: 100%;
}

input,
textarea,
button,
select,
li,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes zoom {
  from {
    transform: scale(1.4);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.zoom-animation {
  animation-name: zoom;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.1, 0.82, 0.25, 1);
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media print {
  @page {
    margin: 0;
    size: 80mm auto;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

.print {
  width: 21cm;
  min-height: 29.7cm;
  box-shadow: 0px 0px 5px 1px #666;
  margin: 20px;
  padding: 15px;
}

.print p {
  margin: 0;
  font-size: 14px;
  line-height: 1.4em;
  color: #000;
}

.print span {
  line-height: 1.4em;
  color: #000;
  font-size: 14px;
}

.print h6 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.print > .header {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.print > .table-header p {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

@page {
  margin: 0;
  size: A4;
}

@media print {
  * {
    page-break-before: avoid;
  }
  .print {
    margin: 0;
    box-shadow: none;
  }
}

@media (min-width: 450px) {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c6c6c6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c6c6c6;
  }
}
